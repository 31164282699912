import { StepEnum } from 'helpers/steps';
import { formatNumber } from 'shared/number';
import { ResponseData } from 'shared/types';

interface TotalPriceMobileStickyBarProps {
  step: StepEnum;
  result: ResponseData | undefined;
}

function TotalPriceMobileStickyBar({ step, result }: TotalPriceMobileStickyBarProps) {
  return step === StepEnum.Results && result?.validSetting ? (
    <div className="col-span-full sm:hidden sticky bottom-0 bg-primary px-4 py-3.5 border-t-2 border-primary-focus">
      <h4 className="flex justify-between text-primary-content">
        <span>Cena celkem:</span>
        <span>{formatNumber(result.total.price, 'Kč')}</span>
      </h4>
    </div>
  ) : null;
}

export default TotalPriceMobileStickyBar;

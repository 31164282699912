import './LoanVariant.scss';
import CheckCircle from 'assets/icons/check-circle-green.svg';
import MinusCircle from 'assets/icons/exclamation-triangle.svg';
import classNames from 'classnames';
import { formatNumber, round } from 'shared/number';
import { trackLoanClicked } from 'helpers/analytics';
import { LoanVariant as LoadVariantProps } from 'shared/financing';

function LoanVariant(props: LoadVariantProps) {
  return (
    <div
      data-loan={props.nameCode}
      className={classNames('LoanVariant', 'grid', 'grid-cols-5', {
        'opacity-50': props.disabled,
      })}
    >
      <div data-theme="dark" className="col-span-full sm:col-span-2 flex flex-col justify-between p-4">
        <div className="font-bold mb-8">{props.name}</div>
        <div>
          <div className="text-sm">Měsíčně od</div>
          <div className="text-4xl my-1.5 font-bold">{formatNumber(round(props.pay, -1))} Kč</div>
          <div className="text-sm">{props.interestInfo}</div>
        </div>
      </div>

      <div className="col-span-full sm:col-span-3 flex flex-col justify-center bg-lightblue p-4">
        <div className="space-y-4 mb-4 LoanVarian__plus-minus-list">
          {props.pluses?.map((plus, i) => (
            <div className="flex items-start space-x-3 LoanVariant--plus" key={`plus-${i}`}>
              <img src={CheckCircle} alt="plus" />
              <span className="text-sm">{plus}</span>
            </div>
          ))}
          {props.minuses?.map((minus, i) => (
            <div className="flex items-start space-x-3 LoanVariant--minus" key={`minus-${i}`}>
              <img src={MinusCircle} alt="minus" />
              <span className="text-sm">{minus}</span>
            </div>
          ))}
        </div>
        <a
          href={props.link}
          onClick={() => trackLoanClicked(props.trackingName)}
          target="_blank"
          rel="noreferrer"
          className="link-primary"
        >
          Reprezentativní příklad
        </a>
      </div>
    </div>
  );
}

export default LoanVariant;

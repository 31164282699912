import classNames from 'classnames';
import Button from 'components/Button';
import { ResultModalContent } from 'helpers/result';
import 'pages/configurator/steps/result/components/InvalidCombinationModal.scss';

type InvalidCombinationModalProps = {
  content: ResultModalContent | null;
  visible: boolean;
  hide: (action: 'confirm' | 'cancel') => void;
};

function InvalidCombinationModal(props: InvalidCombinationModalProps) {
  return props.content ? (
    <>
      <div
        className={classNames('modal', {
          'modal-open': props.visible,
        })}
      >
        <div className="modal-box relative rounded-none p-6 sm:p-10 cursor-auto text-center sm:text-left">
          {props.content.headline && <h4 className="pb-8">{props.content.headline}</h4>}
          {props.content.text && <p className="pb-8">{props.content.text}</p>}
          <div className="flex justify-center sm:justify-between items-center flex-wrap gap-4">
            <Button
              label={props.content.confirmText}
              labelMobile={props.content.confirmTextMobile}
              type="primary"
              onClick={() => props.hide('confirm')}
            />
            <Button label={props.content.cancelText} type="link" onClick={() => props.hide('cancel')} />
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default InvalidCombinationModal;

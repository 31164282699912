import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import HeatTransferBadge from 'pages/configurator/steps/result/components/HeatTransferBadge';
import { ResponseData } from 'shared/types';

interface RatingChangeProps {
  response?: ResponseData;
  styling?: string;
}

function RatingChange(props: RatingChangeProps) {
  const tooltip = (
    <Tooltip
      html="Součinitel prostupu tepla říká, jak dobře je váš dům zateplený
                a jaké máte tepelné ztráty. Rozsah škály je A - F.
                Oproti Průkazu energetické náročnosti budovy (PENB) nebere v úvahu zdroj tepla."
      className="ml-2"
    />
  );

  return props.response?.validSetting ? (
    <table
      className={classNames(
        'table table-compact w-full full-border',
        props.styling,
      )}
    >
      <thead>
        <tr>
          <th colSpan={2}>
            Součinitel prostupu tepla budovy
            {tooltip}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Před rekonstrukcí</td>
          <td>
            <HeatTransferBadge
              rating={props.response.heatTransferCoefficient.before}
            />
          </td>
        </tr>
        <tr>
          <td>Po rekonstrukci</td>
          <td>
            <HeatTransferBadge
              rating={props.response.heatTransferCoefficient.after}
            />
          </td>
        </tr>
      </tbody>
    </table>
  ) : null;
}

export default RatingChange;

import { formatNumber, round } from 'shared/number';
import { HIDE_SAVINGS } from 'shared/global';

interface RecapProps {
  total?: number;
  loanAmount?: number;
  saving?: number;
  pay?: number;
}

function Recap(props: RecapProps) {
  return (
    <div className="my-8 sm:my-12 space-y-4 sm:space-y-6">
      {props.total !== undefined && (
        <h4 className="flex flex-col sm:flex-row gap-1 sm:gap-2">
          <span className="font-normal">Celková cena rekonstrukce:</span>
          <span className="font-bold">{formatNumber(round(props.total, -2))} Kč</span>
        </h4>
      )}
      {props.loanAmount !== undefined && (
        <h4 className="flex flex-col sm:flex-row gap-1 sm:gap-2">
          <span className="font-normal">Výše úvěru:</span>
          <span className="font-bold">{formatNumber(round(props.loanAmount, -2))} Kč</span>
        </h4>
      )}
      {props.saving !== undefined && !HIDE_SAVINGS && (
        <h4 className="flex flex-col sm:flex-row gap-1 sm:gap-2">
          <span className="font-normal">Měsíční úspora:</span>
          <span className="font-bold">{formatNumber(round(props.saving, -2))} Kč</span>
        </h4>
      )}
      {props.pay ? (
        <h4 className="flex flex-col sm:flex-row gap-1 sm:gap-2">
          <span className="font-normal">Měsíční splátka od:</span>
          <span className="font-bold">{formatNumber(round(props.pay, -1))} Kč</span>
        </h4>
      ) : null}
    </div>
  );
}

export default Recap;

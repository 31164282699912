import Page from 'components/layout/Page';
import { GrantFormData } from 'components/GrantTypeStep';
import { Formik } from 'formik';
import { getGrantTypeInitialValues, grantTypeValidationSchema } from 'helpers/form';
import Grants from 'pages/grants/components/Grants';
import Disclaimer from 'components/Disclaimer';

function GrantsPage() {
  const partner = !!new URLSearchParams(window.location.search).get('partner');

  const onSubmit = (_: GrantFormData) => {
    // Nothing is being submitted
  };

  return (
    <Formik
      initialValues={getGrantTypeInitialValues(partner)}
      onSubmit={onSubmit}
      validationSchema={grantTypeValidationSchema(true)}
    >
      <Page title="Jak na dotace">
        <Grants />
        <Disclaimer />
      </Page>
    </Formik>
  );
}

export default GrantsPage;

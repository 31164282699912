import { AxiosError } from 'axios';
import {
  LeadFormFields,
  LeadFormFieldsProps,
} from 'components/form/LeadForm/LeadFormFields';
import { LeadFormProvider } from 'components/form/LeadForm/LeadFormProvider';
import { useState } from 'react';
import { Reasons } from 'shared/reasons';
import { ContactDataNoPartner, LeadOptionalData } from 'shared/types';

interface FormProps extends Omit<LeadFormFieldsProps, 'sent' | 'error'> {
  handleSubmit?: (values: Partial<ContactDataNoPartner>) => Promise<void>;
  reason: Reasons;
  additionalData?: LeadOptionalData;
}

function LeadForm(props: FormProps) {
  const [sent, setSent] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | undefined>();
  const [error, setError] = useState<AxiosError | undefined>();

  const DEFAULT_LOADING_MESSAGE = 'Odesíláme váš požadavek...';

  const handleSetLoadingText = (text?: string) =>
    setLoadingText(text || DEFAULT_LOADING_MESSAGE);
  const handleSetError = (error?: AxiosError) => setError(error);

  return (
    <LeadFormProvider
      additionalData={props.additionalData}
      handleSubmit={props.handleSubmit}
      setLoadingText={handleSetLoadingText}
      setError={handleSetError}
      setSent={setSent}
      reason={props.reason}
    >
      <LeadFormFields
        buttonType={props.buttonType}
        error={error}
        icon={props.icon}
        loadingText={loadingText}
        sent={sent}
        stepsDesc={props.stepsDesc}
        successMessage={props.successMessage}
        title={props.title}
        perex={props.perex}
        buttonLabel={props.buttonLabel}
      />
    </LeadFormProvider>
  );
}

export default LeadForm;

import { type AxiosError } from 'axios';
import classNames from 'classnames';
import Button from 'components/Button';
import Checkbox from 'components/form/Checkbox';
import Input from 'components/form/Input';
import Loading from 'components/layout/Loading';
import { useFormikContext } from 'formik';
import { ContactDataNoPartner } from 'shared/types';

const DEFAULT_SUCCESS_MESSAGE = 'Děkujeme Vám za důvěru. Brzy se vám ozveme.';
const DEFAULT_ERROR_MESSAGE =
  'Ajéje, něco se pokazilo. Zkuste to prosím znovu později.';
const DEFAULT_DESCRIPTION = 'Nechte nám na sebe kontakt a my s vámi:';
const DEFAULT_BUTTON_LABEL = 'Odeslat kontakt';

export interface LeadFormFieldsProps {
  buttonType?: 'primary' | 'secondary';
  error?: AxiosError;
  icon: string;
  loadingText?: string;
  sent: boolean;
  successMessage?: string;
  stepsDesc?: string[];
  title: string;
  perex?: string;
  buttonLabel?: string;
}

export const LeadFormFields = (props: LeadFormFieldsProps) => {
  const formik = useFormikContext<Partial<ContactDataNoPartner>>();
  const successMessage = props.successMessage ?? DEFAULT_SUCCESS_MESSAGE;

  return (
    <div className="p-6">
      <h3
        className={classNames('flex items-center space-x-4', {
          'mb-8': !props.sent,
        })}
      >
        <img src={props.icon} alt="House config" width={54} height="auto" />
        <span>
          {props.error
            ? DEFAULT_ERROR_MESSAGE
            : props.sent
              ? successMessage
              : props.title}
        </span>
      </h3>
      {!props.sent && !props.error && (
        <>
          <p className="text-lg font-bold mb-4">
            {props.perex !== undefined ? props.perex : DEFAULT_DESCRIPTION}
          </p>
          {props.stepsDesc?.length && (
            <ul className="list-disc space-y-2 pl-0 leading-5 ml-4 mb-8 text-lg font-normal">
              {props.stepsDesc.map((desc: string, i: number) => (
                <li key={`desc-${i}`}>{desc}</li>
              ))}
            </ul>
          )}
          <Input
            name="tel"
            type="tel"
            fullWidth
            placeholder="+420"
            maxLength={16}
          />

          <Checkbox
            name="agreement"
            label={
              <>
                Potvrzuji, že jsem se seznámil/a se způsobem{' '}
                <a
                  href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  zpracování osobních údajů
                </a>
              </>
            }
            className="mt-8 sm:mt-12"
          />
          <div
            className={
              props.buttonType === 'primary' ? 'md:text-left' : 'md:text-right'
            }
          >
            <Button
              type={props.buttonType || 'primary'}
              label={props.buttonLabel || DEFAULT_BUTTON_LABEL}
              onClick={() => {
                formik.setFieldTouched('agreement');
                formik.submitForm();
              }}
              className="w-full md:w-auto"
              isSubmit
            />
          </div>
          <Loading delayed loadingText={props.loadingText} />
        </>
      )}
    </div>
  );
};

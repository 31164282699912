import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';

interface CheckboxProps<T extends { [key: string]: unknown }> {
  name: keyof T;
  label: string | ReactNode;
  className?: string;
}

function Checkbox<T extends { [key: string]: unknown }>(props: CheckboxProps<T>) {
  const { label, className } = props;
  const { values, setFieldValue, touched, errors } = useFormikContext<T>();
  const checked = !!values[props.name];
  const error = touched[props.name] && errors[props.name];

  return (
    <div className={classNames('form-control', className)}>
      <label className="label cursor-pointer justify-start space-x-4">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => setFieldValue(props.name as string, e.target.checked)}
          className={classNames('checkbox', 'checkbox-primary', 'bg-white', {
            'checkbox-error': error,
          })}
        />
        <span className="label-text">{label}</span>
      </label>
      {error && <div className="text-sm text-error">{error as string}</div>}
    </div>
  );
}

export default Checkbox;

import ClockCounter from 'assets/icons/chevron-clock-counter.svg';
import Clock from 'assets/icons/chevron-clock.svg';
// import Check from "assets/icons/check-circle-green.svg";
// import Minus from "assets/icons/minus-circle-black.svg";
import { formatNumber } from 'shared/number';
import { GrantTypeEnum } from 'shared/types';

const dataGrantDesktop = (totalGrant?: { [key in GrantTypeEnum]: number }) => ({
  headCells: [
    { cell: 'NZÚ Oprav dům po babičce', preCell: 'Vyplatí se' },
    { cell: 'NZÚ Light' },
  ],
  rows: [
    {
      highlighted: true,
      cells: [
        { cell: 'Kdy dotaci dostanu' },
        {
          cell: (
            <>
              <img src={ClockCounter} alt="" />
              Před rekonstrukcí
            </>
          ),
        },
        {
          cell: (
            <>
              <img src={Clock} alt="" />
              Po rekonstrukci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: totalGrant ? 'Celková výše dotace' : 'Maximální výše dotace',
          tooltip: totalGrant
            ? 'Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám.'
            : undefined,
        },
        {
          cell: (
            <>
              {totalGrant
                ? `${formatNumber(totalGrant[GrantTypeEnum.Babicka])} Kč`
                : 'Až 1 milion Kč na důkladné zateplení + více na další opatření'}
            </>
          ),
        },
        {
          cell: (
            <>
              {totalGrant
                ? `${formatNumber(totalGrant[GrantTypeEnum.NZUL])} Kč`
                : 'Až 0,5 mil Kč na zateplení + více na další opatření'}
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: 'Rozsah rekonstrukce',
          tooltip:
            'Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
        { cell: 'Pouze komplexní rekonstrukce' },
        { cell: 'Dílčí rekonstrukce' },
      ],
    },
    {
      cells: [
        {
          cell: 'Podmínka trvalého bydliště',
          tooltip:
            'V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ',
        },
        { cell: 'Ano' },
        { cell: 'Ano' },
      ],
    },
    {
      cells: [
        {
          cell: 'Podmínka komplexního zateplení',
          tooltip:
            'Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
        { cell: 'Ano' },
        { cell: 'Ne' },
      ],
    },
    {
      cells: [
        {
          cell: 'Kombinační bonus',
          tooltip:
            '50 000 Kč u Babičky/30 000 Kč u NZU Light za kombinaci oblasti podpory A – zateplení a oblasti podpory C - zdroje energie/výměna zdrojů tepla',
        },
        { cell: '50 000 Kč za kombinaci opatření oblasti A + C*' },
        { cell: '30 000 Kč za kombinaci opatření oblasti A + C' },
      ],
    },
    {
      cells: [
        {
          cell: 'Bonus pro znevýhodněné kraje',
          tooltip:
            'Bonus 5 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.',
        },
        { cell: 'Navýšení dotace o 5 %' },
        { cell: 'Navýšení dotace o 5 %' },
      ],
    },
    {
      cells: [
        {
          cell: 'Bonus za nezaopatřené dítě',
          tooltip:
            'Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.',
        },
        { cell: '50 000 Kč za každé dítě' },
        { cell: 'Ne' },
      ],
    },
  ],
});
const dataGrantMobile = (totalGrant?: { [key in GrantTypeEnum]: number }) => ({
  switch: true,
  addEmptyHeadCell: false,
  headCells: [
    { cell: 'NZÚ Oprav dům po babičce', preCell: 'Vyplatí se' },
    { cell: 'NZÚ Light' },
  ],
  rows: [
    {
      cells: [
        {
          cell: (
            <>
              <img src={ClockCounter} alt="" />
              Dotace <b>už před</b> rekonstrukcí
            </>
          ),
        },
        {
          cell: (
            <>
              <img src={Clock} alt="" />
              Dotace <b>až po</b> rekonstrukci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          tooltip: totalGrant
            ? 'Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám.'
            : undefined,
          cell: totalGrant ? (
            <>
              Celková výše dotace{' '}
              <b>{formatNumber(totalGrant[GrantTypeEnum.Babicka])} Kč</b>
            </>
          ) : (
            'Až 1 milion Kč na důkladné zateplení + více na další opatření'
          ),
        },
        {
          status: true,
          tooltip: totalGrant
            ? 'Maximální možná výše dotační podpory v rámci dotace Nová zelená úsporám.'
            : undefined,
          cell: totalGrant ? (
            <>
              Celková výše dotace{' '}
              <b>{formatNumber(totalGrant[GrantTypeEnum.NZUL])} Kč</b>
            </>
          ) : (
            'Až 500 000 Kč na zateplení + více na další opatření'
          ),
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              Pouze <b>komplexní</b> rekonstrukce
            </>
          ),
          tooltip:
            'Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
        {
          status: true,
          cell: <>Dílčí rekonstrukce</>,
          tooltip:
            'Rozsahem rekonstrukce může být důkladné zateplení obálky budovy nebo jen částečné zateplení. Důkladnému zateplení odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>S podmínkou</b> trvalého bydliště
            </>
          ),
          tooltip:
            'V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ',
        },
        {
          status: true,
          cell: (
            <>
              <b>S podmínkou</b> trvalého bydliště
            </>
          ),
          tooltip:
            'V nemovitosti budete mít po dobu alespoň 10 let trvalé bydliště. ',
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>S podmínkou</b> komplexního zateplení
            </>
          ),
          tooltip:
            'Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
        {
          status: false,
          cell: (
            <>
              <b>Bez podmínky</b> komplexního zateplení
            </>
          ),
          tooltip:
            'Podmínkou může být optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy A nebo B.',
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>Kombinační bonus 50 000 Kč</b> za kombinaci opatření oblasti A
              + C*
            </>
          ),
          tooltip:
            'Bonus ve výši 50 000 Kč za vybrané kombinace opatření – např. zateplení zdí a výměna oken + fotovoltaika',
        },
        {
          status: true,
          cell: (
            <>
              <b>Kombinační bonus 30 000 Kč</b> za kombinaci opatření oblasti A
              + C*
            </>
          ),
          tooltip:
            'Bonus ve výši 30 000 Kč za vybrané kombinace opatření – např. zateplení zdí + fotovoltaika = 30 000 Kč',
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>Navýšení dotace o 5%</b> pro znevýhodněné kraje
            </>
          ),
          tooltip:
            'Bonus 5 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.',
        },
        {
          status: true,
          cell: (
            <>
              <b>Navýšení dotace o 5%</b> pro znevýhodněné kraje
            </>
          ),
          tooltip:
            'Bonus 5 % z celkové výše dotace pro žadatele z Karlovarského, Moravskoslezského a Ústeckého kraje a z obcí podporovaných státem.',
        },
      ],
    },
    {
      cells: [
        {
          status: true,
          cell: (
            <>
              <b>50 000 Kč</b> za každé dítě
            </>
          ),
          tooltip:
            'Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.',
        },
        {
          status: false,
          cell: (
            <>
              <b>Bez bonusu</b> za neopatřené dítě
            </>
          ),
          tooltip:
            'Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání.',
        },
      ],
    },
  ],
});
export const dataProgress = {
  addEmptyHeadCell: false,
  headCells: [{ cell: 'NZÚ Oprav dům po babičce' }, { cell: 'NZÚ Light' }],
  rows: [
    {
      cells: [
        {
          cell: (
            <>
              <span data-line />
              <i data-stepid>1</i>
              návrh energetických úspor
            </>
          ),
          tooltip:
            'Návrh energetických úspor obsahuje odhad cen jednotlivých opatření a dotací na jednotlivá opatření.',
        },
        {
          cell: (
            <>
              <span data-line />
              <i data-stepid>1</i>
              návrh energetických úspor
            </>
          ),
          tooltip:
            'Návrh energetických úspor obsahuje odhad cen jednotlivých opatření a dotací na jednotlivá opatření.',
        },
      ],
    },

    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>2</i>
              odborný posudek
            </>
          ),
          tooltip:
            'Odborný posudek v oblasti novostavby a zateplení se zpravidla skládá ze dvou částí – projektové dokumentace a energetického hodnocení budovy, u ostatních opatření je jednodušší. Posudek musí být zpracován osobou s příslušnou autorizací či oprávněním.',
        },
        {
          cell: (
            <>
              <i data-stepid>2</i>
              odborný posudek
            </>
          ),
          tooltip: `Odborný posudek v oblasti novostavby a zateplení se zpravidla skládá ze dvou částí – projektové dokumentace a energetického hodnocení budovy, u ostatních opatření je jednodušší. Posudek musí být zpracován osobou s příslušnou autorizací či oprávněním. 
            <br>
            <br>
            Posudek pro oblasti A, C, je dokládán jako Zpráva o navrhovaných opatřeních, jejíž vzor je zveřejněn na webových stránkách programu NZU Light.`,
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>3</i>
              žádost o dotaci
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>3</i>
              žádost o dotaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>4</i>
              dostanu dotaci
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>4</i>
              dostanu dotaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>5</i>
              žádost o úvěr
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>5</i>
              žádost o úvěr
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>6</i>
              rekonstruuji
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>6</i>
              rekonstruuji
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>7</i>
              dokládám realizaci
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>7</i>
              dokládám realizaci
            </>
          ),
        },
      ],
    },
    {
      cells: [
        {
          cell: (
            <>
              <i data-stepid>8</i>
              mám hotovo
            </>
          ),
        },
        {
          cell: (
            <>
              <i data-stepid>8</i>
              mám hotovo
            </>
          ),
        },
      ],
    },
  ],
};

export function getGrantTableData(
  mobile: boolean,
  totalGrant?: { [key in GrantTypeEnum]: number },
) {
  return (mobile ? dataGrantMobile : dataGrantDesktop)(totalGrant);
}

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Constants } from 'shared/types';

interface ConstantsContextType {
  values: Constants | undefined;
  isLoading: boolean;
}

const defaultContextValue = {
  values: undefined,
  isLoading: false,
};

const ConstantsContext =
  React.createContext<ConstantsContextType>(defaultContextValue);

export const ConstantsProvider = ({ children }: PropsWithChildren) => {
  const [contextValue, setContextValue] =
    useState<ConstantsContextType>(defaultContextValue);

  useEffect(() => {
    setContextValue({ values: undefined, isLoading: true });
    fetch('/load-constants', {
      method: 'GET',
    })
      .then((res) => {
        if (!res.ok) {
          setContextValue({ values: undefined, isLoading: false });
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => setContextValue({ values: res, isLoading: false }))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <ConstantsContext.Provider value={contextValue}>
      {children}
    </ConstantsContext.Provider>
  );
};

export default ConstantsContext;

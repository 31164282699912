import LeadFormIcon from 'assets/icons/lead.svg';
import classNames from 'classnames';
import LeadForm from 'components/form/LeadForm/LeadForm';
import { GrantFormData } from 'components/GrantTypeStep';
import { useFormikContext } from 'formik';
import Table from 'pages/grants/components/Table';
import { dataProgress as progressTableData } from 'pages/grants/helpers/tableData';
import { useRef } from 'react';
import { Reasons } from 'shared/reasons';
import { useShouldShowLeadForm } from 'stores/appStore';

function Step3() {
  const { values } = useFormikContext<GrantFormData>();
  const isBabickaEligible =
    values.complexReconstruction &&
    values.permanentResidence &&
    values.anotherPermanentResidence === false;
  // Save the value of shouldShowLeadForm to a ref so that the success message can be still shown
  const shouldShowLeadForm = useRef(useShouldShowLeadForm());
  // Skip empty rows when showing only NZUL
  // const data = isBabickaEligible
  //   ? progressTableData
  //   : {
  //       ...progressTableData,
  //       rows: progressTableData.rows.filter(
  //         ({ cells }) => cells[1].cell !== null,
  //       ),
  //       headCells: progressTableData.headCells.slice(1),
  //     };
  // const mobileData = Object.assign({}, data, { switch: isBabickaEligible });
  const data = {
    ...progressTableData,
    rows: progressTableData.rows.filter(({ cells }) => cells[1].cell !== null),
    headCells: [],
  };

  return (
    <>
      <h2 className="mb-14">Jak probíhá žádost o dotaci</h2>
      <Table
        data={data}
        className={classNames('grant-progress-table', 'Table-desktop', {
          // 'grant--babicka': isBabickaEligible,
        })}
      />
      <Table
        data={data}
        className={classNames('grant-progress-table', 'Table-mobile', {
          // 'grant--babicka': isBabickaEligible,
        })}
      />

      <p className="italic mt-4 text-sm">
        * <strong>A</strong> jsou úpravy zateplění, <strong>C</strong> jsou
        zdroje energie.
      </p>

      {shouldShowLeadForm.current && (
        <div className="bg-lightblue mt-20 mb-6">
          <LeadForm
            title="Chcete pomoct s dalšími kroky?"
            icon={LeadFormIcon}
            stepsDesc={[
              'Pomůžeme vám podat žádost o dotaci',
              'Doporučíme naše partnery na rekonstrukci',
              'Projdeme s vámi detailně možnosti financování',
            ]}
            reason={Reasons.Grant}
            additionalData={{ isBabickaEligible }}
          />
        </div>
      )}
    </>
  );
}

export default Step3;

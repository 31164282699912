import classNames from 'classnames';
import { GrantFormData } from 'components/GrantTypeStep';
import MessageBox from 'components/MessageBox';
import { useFormikContext } from 'formik';
import Table from 'pages/grants/components/Table';
import { getGrantTableData } from 'pages/grants/helpers/tableData';
import { useAppStore } from 'stores/appStore';

function Step2() {
  const { values } = useFormikContext<GrantFormData>();
  const isBabickaEligible =
    values.complexReconstruction &&
    values.permanentResidence &&
    values.anotherPermanentResidence === false;
  const bothGrantsIneligible =
    values.permanentResidence === false ||
    values.anotherPermanentResidence === true;
  const partner = !!new URLSearchParams(window.location.search).get('partner');
  const storeState = useAppStore.getState();
  const totalGrant = partner ? undefined : storeState.grantData?.totalGrant;
  const data = getGrantTableData(false, totalGrant);
  const mobileDataRaw = getGrantTableData(true, totalGrant);

  // enhance data for desktop by last empty row
  data.rows.push({
    cells: [{ cell: <></> }, { cell: <></> }],
  });

  const sliceStart = isBabickaEligible ? 0 : 1;
  const mobileData = {
    ...mobileDataRaw,
    switch: isBabickaEligible,
    headCells: mobileDataRaw.headCells.slice(sliceStart, 2),
    rows: mobileDataRaw.rows.map((row) => ({
      cells: row.cells.slice(sliceStart, 2),
    })),
  };

  return (
    <>
      <h2 className="mb-6">
        {isBabickaEligible ? 'Srovnání dotací' : 'Souhrn dotace'}
      </h2>
      <h4 className="mb-14">
        Zohlednili jsme výsledky a vaše předchozí odpovědi.
      </h4>

      <Table
        data={data}
        // both choices available
        className={classNames('grant-table', 'Table-desktop', {
          'grant--babicka': isBabickaEligible,
          'opacity-50': bothGrantsIneligible,
        })}
      >
        <colgroup>
          {isBabickaEligible ? (
            <>
              <col />
              <col width="30%" />
              <col width="30%" />
            </>
          ) : (
            <>
              <col width="55%" />
              <col width="45%" />
            </>
          )}
        </colgroup>
      </Table>

      <Table
        data={mobileData}
        className={classNames('grant-table', 'Table-mobile', {
          'table-grant--babicka': isBabickaEligible,
          'opacity-50': bothGrantsIneligible,
        })}
      />

      <p className="italic mt-4 text-sm mb-14">
        * <strong>A</strong> jsou úpravy zateplění, <strong>C</strong> jsou
        zdroje energie.
      </p>

      {bothGrantsIneligible && (
        <MessageBox
          text="Je nám líto, ale dotaci nelze poskytnout. Zřejmě nesplňujete základní podmínky."
          severity="warning"
        />
      )}
    </>
  );
}

export default Step2;

import axios, { AxiosError } from 'axios';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Disclaimer from 'components/Disclaimer';
import Page from 'components/layout/Page';
import { getInitialValues, validationSchema } from 'helpers/form';
import { measureDefaultSetting } from 'helpers/measure';
import { getLink, RouteEnum } from 'helpers/routes';
import { StepEnum } from 'helpers/steps';
import Configurator from 'pages/configurator/components/Configurator';
import {
  LOADING_TEXT_COMPUTING,
  LOADING_TEXT_SENDING,
} from 'pages/configurator/helpers/loadingTexts';
import {
  FormAndContactData,
  FormData,
  Loan,
  MeasureSetting,
  RequestData,
  ResponseData,
  ValidatedFormData,
} from 'shared/types';
import { useAppStore } from 'stores/appStore';

// TODO: rozdelit na separatni kopolenty
export default function ConfiguratorPage() {
  const [step, setStep] = useState<StepEnum>(StepEnum.Step1);
  const [setting, setSetting] = useState<MeasureSetting>(measureDefaultSetting);
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<ResponseData | undefined>();
  const [loans, setLoans] = useState<Loan[] | undefined>();
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputDataStore = useAppStore();
  const navigate = useNavigate();
  const partner = !!new URLSearchParams(window.location.search).get('partner');

  function onSubmit(
    values: FormData,
    { setFieldValue, setTouched }: FormikHelpers<FormData>,
  ) {
    const lastStep = step === StepEnum.ThankYou - 1;
    const request: RequestData = {
      formData: lastStep
        ? (values as ValidatedFormData)
        : (values as FormAndContactData),
      measureSetting: setting,
    };

    // Submitting sets all field to touched, but we need the rest fields to be untouched
    if (!lastStep) {
      setTouched({});
    }

    if (lastStep) {
      setLoadingText(LOADING_TEXT_SENDING);
    } else {
      setLoadingText(LOADING_TEXT_COMPUTING);
    }

    setIsLoading(true);

    const response = axios.post(lastStep ? '/submit' : '/compute', request);

    response
      .then(({ data }) => {
        setResult(data.result);
        setLoans(data.loans);

        if (lastStep) {
          // Make choices persistent in Local storage
          inputDataStore.setConfiguratorData(values);
          inputDataStore.setGrantData({
            totalGrant: data.result.totalGrant,
          });
          inputDataStore.completeStep(2);
          inputDataStore.completeStep(3);
          // Navigate to home page
          navigate(getLink(RouteEnum.HomePage));
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoadingText(undefined);
        setIsLoading(false);
      });

    return response;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(partner),
    onSubmit: onSubmit,

    validationSchema: validationSchema(step, partner),
  });

  return (
    <Page title="Konfigurátor">
      <FormikProvider value={formik}>
        <Configurator
          step={step}
          setStep={setStep}
          error={error}
          setError={setError}
          loadingText={loadingText}
          setLoadingText={setLoadingText}
          setting={setting}
          setSetting={setSetting}
          result={result}
          loans={loans}
          isLoading={isLoading}
        />
      </FormikProvider>
      <Disclaimer />
    </Page>
  );
}
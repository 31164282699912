type AssetsProps = Record<string, any>;
type Props = {
  assets: AssetsProps;
};

function Prelude(props: Props) {
  return (
    <div className="bg-table-border">
      <div className="max-w-6xl m-auto">
        <div className="flex items-center px-4 py-8">
          <div>
            <h1 className="mb-6 font-medium md:font-normal text-2xl md:text-5xl">
              Průvodce vaší energeticky úspornou rekonstrukcí
            </h1>
            <ul className="list-disc list-disc--prelude space-y-2 pl-0 leading-5">
              <li>V konfigurátoru vyberete rozsah rekonstrukce</li>
              <li>Navrhne vhodné financování a dotaci</li>
              <li>Zjistíte, jak podat žádost o dotaci</li>
              <li>S přípravou na rekonstrukci vám v ČSOB pomůžeme</li>
            </ul>
          </div>
          <img
            src={props.assets['GeckoWithConfigurator']}
            alt=""
            width={412}
            height={347}
            className="hidden md:block"
          />
        </div>
      </div>
    </div>
  );
}

function Step2DoneMessage(props: Props) {
  // TODO: ikonka je ve Figme rozbita, tak je potreba ji upravit a pak preimportovat, https://www.figma.com/file/Gu8IMMKZLcIJ6AXQqCSwNP?node-id=9268:18380&mode=dev#654114157
  return (
    <>
      <img src={props.assets['DoneMail']} width={40} height="auto" alt="" />
      Váš návrh rekonstrukce a financování jsme vám poslali na váš e-mail.
    </>
  );
}

function Step3DoneMessage(props: Props) {
  return (
    <>
      <img src={props.assets['DonePhone']} width={40} height="auto" alt="" />
      Máme na vás kontakt a ozveme se vám.
    </>
  );
}

export { Prelude, Step2DoneMessage, Step3DoneMessage };

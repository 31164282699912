import { trackHomePageButtonClicked } from 'helpers/analytics';
import { getLink, RouteEnum } from 'helpers/routes';

interface Props {
  openModal: () => void;
  completeStep: (step: number) => void;
}
export interface StepProps {
  title: string;
  description?: string[];
  button?: {
    isVisible?: boolean;
    label: string;
    labelMobile?: string;
  } & ({ onClick: () => void } | { to: string; target?: string });
  transparent?: boolean;
}

export const getHomeSteps: (props: Props) => StepProps[] = ({ openModal, completeStep }) => [
  {
    title: 'Chci uspořit na energiích',
    description: [
      'Zvýším si pohodlí a komfort svého domu',
      'Zhodnotím svou nemovitost',
      'S přípravou na rekonstrukci mi v ČSOB pomohou',
    ],
    transparent: true,
  },
  {
    title: 'Chci vědět, jaké mám možnosti rekonstrukce a za kolik',
    description: [
      'Využijte konfigurátor úsporné renovace, říkáme mu „Rekalkula“. Nespočítá renovaci na korunu přesně, ale odhadne náklady na různé technologie a kolik se jimi dá ušetřit.',
      'Pro výpočet je třeba znát alespoň základní popis vašeho domu. Ale nebojte, všechno vyplníte od stolu, žádné hledání výkresů nebo účtů.',
    ],
    button: {
      label: 'Spočítat rekonstrukci',
      to: getLink(RouteEnum.Configurator),
      onClick: () => trackHomePageButtonClicked('02-Chci vědět, jaké mám možnosti rekonstrukce a za kolik'),
    },
  },
  {
    title: 'Vysvětlí mi, jaké budou další kroky rekonstrukce',
    description: [
      'Nabídneme partnery pro přípravu a realizaci projektu',
      'Projdeme s vámi detailně možnosti financování'
    ],
    hideButtonOnDone: true,
    button: {
      label: 'Domluvit schůzku',
      onClick: () => {
        openModal();
        trackHomePageButtonClicked('03-Získám návrh na financování');
      },
    },
  },
  {
    title: 'Budu vědět, jak na dotace',
    description: ['Dostanete srovnání vhodných dotací', 'Zjistíte, v jakou chvíli můžete požádat o dotaci'],
    button: {
      label: 'Porovnat dotace',
      to: getLink(RouteEnum.Grant),
      onClick: () => trackHomePageButtonClicked('04-Budu vědět, jak na dotace'),
    },
  },
  // {
  //   title: 'Vyberu si projektanta nebo architekta',
  //   description: [
  //     'Specialista zhotoví energetickou studii domu',
  //     'Připraví projektovou dokumentaci',
  //     'Připraví podklady pro stavební firmu',
  //   ],
  //   button: {
  //     label: 'Partnerští specialisté',
  //     to: 'https://www.pasivnidomy.cz/poradenstvi/',
  //     target: '_blank',
  //     onClick: () => {
  //       completeStep(5);
  //       trackHomePageButtonClicked('05-Vyberu si projektanta nebo architekta');
  //     },
  //   },
  // },
  {
    title: 'Potřebuji zjistit, jak a kde podat žádost o dotaci',
    description: [
      'Jak probíhá podání žádosti',
      'Jaké dokumenty k tomu potřebujete',
      'Pomůžeme vám i s bankovní identitou',
    ],
    button: {
      label: 'Ukázat postup',
      to: getLink(RouteEnum.HowToApply),
      onClick: () => trackHomePageButtonClicked('06-Potřebuji zjistit, jak a kde podat žádost o dotaci'),
    },
  },
  // {
  //   title: 'Vyberu si partnerskou stavební firmu',
  //   description: [
  //     'Ověřené firmy s patřičnou zkušeností',
  //     'Dodají vše potřebné, namontují, postaví',
  //     'V kombinaci s financováním od ČSOB získáte výhody',
  //   ],
  //   button: {
  //     label: 'Partnerské stavební firmy',
  //     to: 'https://www.csob.cz/csob/jak-na-uspory-energii/partneri',
  //     target: '_blank',
  //     onClick: () => {
  //       completeStep(7);
  //       trackHomePageButtonClicked('07-Vyberu si partnerskou stavební firmu');
  //     },
  //   },
  // },
  {
    title: 'Rekonstruuji svůj rodinný dům',
  },
  {
    title: 'Mám úsporný a pohodlný dům!',
  },
];

import { ConstantsProvider } from 'contexts/constantsContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { RouteEnum } from 'helpers/routes';
import Layout from 'Layout';
import ConfiguratorPage from 'pages/configurator/ConfiguratorPage';
import Error404Page from 'pages/error/Error404Page';
import GrantsPage from 'pages/grants/GrantsPage';
import HomePage from 'pages/home/HomePage';
import HowToApplyPage from 'pages/how-to-apply/HowToApplyPage';

function App() {
  return (
    <ConstantsProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route
              path={RouteEnum.Configurator}
              element={<ConfiguratorPage />}
            />
            <Route path={RouteEnum.Grant} element={<GrantsPage />} />
            <Route path={RouteEnum.HowToApply} element={<HowToApplyPage />} />
            <Route path={RouteEnum.NotFound} element={<Error404Page />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConstantsProvider>
  );
}

export default App;

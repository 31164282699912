import classNames from 'classnames';
import { ReactNode, useContext, useState } from 'react';

import LeftBar from 'components/layout/LeftBar';
import Loading from 'components/layout/Loading';
import Navigation from 'components/layout/Navigation';
import ConstantsContext from 'contexts/constantsContext';
import ConstantsNotLoaded from 'pages/configurator/components/ConstantsNotLoaded';

interface AppLayoutProps<T extends number> {
  children: ReactNode;
  showLeftBar?: boolean;
  actualStep: T;
  steps: { [key in T]: string | undefined };
  tips?: { [key in T]: string | string[] | null };
  tipHeadline: string;
  nextStepLabel?: string;
  nextStepLabelMobile?: string;
  goToNextStep?: () => boolean | Promise<boolean>; // returns true if form is valid
  previousStepLabel?: string;
  previousStepLabelMobile?: string;
  previousStepIcon?: 'chevron-left' | 'cycle';
  goToPreviousStep?: () => void;
  isSubmittingStep?: boolean;
  loading?: boolean;
  loadingDelayed?: boolean;
  loadingText?: string;
  needData?: boolean;
  alternativeButton?: ReactNode;
}

function PageLayout<T extends number>(props: AppLayoutProps<T>) {
  const { values: constants, isLoading: loadingConstants } =
    useContext(ConstantsContext);
  const [mobileTipVisible, setMobileTipVisible] = useState(false);
  const goToNextStep = async (mobile: boolean) => {
    const validationSuccessful = (await props.goToNextStep?.()) ?? true;
    if (validationSuccessful) {
      if (mobile && props.tips?.[props.actualStep]) {
        // Shows tip for the last visible step – see <Tip /> for more info
        setMobileTipVisible(true);
      }
      window.scrollTo(0, 0);
    }
  };

  const goToPreviousStep = () => {
    props.goToPreviousStep?.();
    setMobileTipVisible(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="grid grid-cols-10 bg-white">
      {(props.showLeftBar ?? true) && (
        <LeftBar
          actualStep={props.actualStep}
          steps={props.steps}
          tips={props.tips}
          tipHeadline={props.tipHeadline}
          mobileTipVisible={mobileTipVisible}
          toggleMobileTipVisible={() => setMobileTipVisible((v) => !v)}
          goToPreviousStep={goToPreviousStep}
        />
      )}

      <div
        className={classNames(
          'flex flex-col justify-between relative col-span-full',
          {
            'lg:col-span-7': props.showLeftBar ?? true,
            'hidden lg:flex': (props.showLeftBar ?? true) && mobileTipVisible,
          },
        )}
      >
        <div className="px-4 sm:px-8 py-6 sm:py-16">
          {props.needData && !constants && !loadingConstants ? (
            <ConstantsNotLoaded />
          ) : (
            props.children
          )}
        </div>
        {props.needData && !constants ? null : (
          <Navigation
            alternativeButton={props.alternativeButton}
            backClick={goToPreviousStep}
            backIcon={props.previousStepIcon}
            backLabel={props.previousStepLabel}
            backLabelMobile={props.previousStepLabelMobile}
            isNextSubmit={props.isSubmittingStep}
            nextLabel={props.nextStepLabel}
            nextLabelMobile={props.nextStepLabelMobile}
            nextClick={goToNextStep}
          />
        )}
        <Loading
          delayed={props.loadingDelayed}
          loadingText={props.loadingText}
        />
      </div>
    </div>
  );
}

export default PageLayout;

import Block from 'components/Block';
import { useFormikContext } from 'formik';
import GrantTypeCard from 'pages/configurator/components/GrantTypeCard';
import { getGrantTypes } from 'shared/grantTypes';
import { FormData, GrantTypeEnum, MeasureKey } from 'shared/types';

interface GrantTypeSwitcherProps {
  isBabickaEligible: boolean;
  onGrantTypeSelect: (grantType: GrantTypeEnum) => void;
  toggleSetting: (measure: MeasureKey) => void;
}

function GrantTypeSwitcher(props: GrantTypeSwitcherProps) {
  const { values } = useFormikContext<FormData>();
  const grantTypes = getGrantTypes(values);
  const { isBabickaEligible } = props;
  const handleOnGrantSelect = (key: GrantTypeEnum) => {
    // turn off project documentation if selected grant type is NZU
    if (key === GrantTypeEnum.NZUL) {
      props.toggleSetting(MeasureKey.Documentation);
    }
    props.onGrantTypeSelect(key);
  };
  const bothGrantsIneligible =
    !values.permanentResidence || values.anotherPermanentResidence === true;

  return !bothGrantsIneligible ? (
    <Block>
      <h5 className="mb-4">Doporučená dotace</h5>
      <div className="grid sm:grid-cols-2 gap-4">
        {Object.entries(grantTypes).map(([key, value]) => {
          const type = isBabickaEligible ? 'selectable' : 'static';
          const available =
            key === GrantTypeEnum.Babicka ? isBabickaEligible : true;

          return (
            <GrantTypeCard
              key={key}
              {...value}
              type={type}
              available={available}
              selected={key === values.grantType}
              onSelect={() => handleOnGrantSelect(key as GrantTypeEnum)}
            />
          );
        })}
      </div>
    </Block>
  ) : null;
}

export default GrantTypeSwitcher;

import BubbleConnectionHorizontal from 'assets/bubble-connection-horizontal.svg';
import BubbleConnectionVertical from 'assets/bubble-connection-vertical.svg';
import Gecko from 'assets/gecko.png';
import InfoCircle from 'assets/icons/info-circle.svg';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import Loading from 'components/layout/Loading';
import MessageBox from 'components/MessageBox';
import { useFormikContext } from 'formik';
import {
  getBabickaModalContent,
  getSourceSwapModalContent,
  getWallInsulationModalContent,
  ResultModalSetting,
} from 'helpers/result';
import { LOADING_TEXT_COMPUTING } from 'pages/configurator/helpers/loadingTexts';
import GrantTypeSwitcher from 'pages/configurator/steps/result/components/GrantTypeSwitcher';
import InvalidCombinationModal from 'pages/configurator/steps/result/components/InvalidCombinationModal';
import MeasureMobile from 'pages/configurator/steps/result/components/MeasureMobile';
import MeasuresTable from 'pages/configurator/steps/result/components/MeasuresTable';
import MeasuresTableMobile from 'pages/configurator/steps/result/components/MeasuresTableMobile';
import RatingChange from 'pages/configurator/steps/result/components/RatingChange';
import RatingChangeMobile from 'pages/configurator/steps/result/components/RatingChangeMobile';
import SavingsResult from 'pages/configurator/steps/result/components/SavingsResult';
import { useState } from 'react';
import {
  FormData,
  GrantTypeEnum,
  MeasureKey,
  MeasureSetting,
  ResponseData,
} from 'shared/types';

interface ResultsStepProps {
  setting: MeasureSetting;
  toggleSetting: (measure: MeasureKey) => void;
  response?: ResponseData;
  isLoading: boolean;
}

function ResultsStep(props: ResultsStepProps) {
  const { setting, toggleSetting, response } = props;
  const { values, setFieldValue, submitForm } = useFormikContext<FormData>();
  const [modalSetup, setModalSetup] = useState<ResultModalSetting>(null);

  const bothGrantsIneligible =
    values.permanentResidence === false ||
    values.anotherPermanentResidence === true;

  const onToggle = (key: MeasureKey) => {
    const shouldSwitchFVOn =
      key === 'heatPump' &&
      !setting.heatPump &&
      !setting.gasCondensingBoiler &&
      !setting.fv &&
      !values.fvInstalled;
    const shouldShowSourceSwapModal =
      (key === 'heatPump' &&
        !setting.heatPump &&
        setting.gasCondensingBoiler) ||
      (key === 'gasCondensingBoiler' &&
        !setting.gasCondensingBoiler &&
        setting.heatPump);
    const shouldShowWallInsulationModal =
      key === 'wallInsulation' &&
      setting.wallInsulation &&
      (setting.windows || setting.heatPump);

    const afterSubmitHandler = (response: void) => {
      const {
        data: { result },
      } = response as unknown as AxiosResponse<{ result: ResponseData }>;
      const shouldShowBabickaModal =
        setting[key] &&
        values.grantType === GrantTypeEnum.Babicka &&
        result.validSetting &&
        !result.isBabickaEligible;

      // If babicka is selected and heatTransferCoefficient is lower than B, show modal
      if (shouldShowBabickaModal) {
        setModalSetup({
          ...getBabickaModalContent(key),
          onConfirm: () => {
            toggleSetting(key);
            submitForm();
          },
          onCancel: () => {
            setFieldValue('grantType', GrantTypeEnum.NZUL);
            submitForm();
          },
        });
      }
    };

    // Switching HeatPump on will switch FV on too when already doesn't have one
    if (shouldSwitchFVOn) {
      toggleSetting(MeasureKey.Fv);
      submitForm();
    }

    // Switching HeatPump on will show modal and switch GasCondensingBoiler off and vice versa
    if (shouldShowSourceSwapModal) {
      setModalSetup({
        ...getSourceSwapModalContent(key),
        onConfirm: () => {
          toggleSetting(MeasureKey.HeatPump);
          toggleSetting(MeasureKey.GasCondensingBoiler);
          if (key === 'heatPump' && !setting.fv && !values.fvInstalled) {
            toggleSetting(MeasureKey.Fv);
          }
          submitForm();
        },
      });

      // Switching wall insulation off while windows or heatPump are on will show modal
    } else if (shouldShowWallInsulationModal) {
      setModalSetup({
        ...getWallInsulationModalContent(),
        onCancel: () => {
          toggleSetting(key);
          submitForm().then(afterSubmitHandler);
        },
      });
    } else {
      toggleSetting(key);
      submitForm().then(afterSubmitHandler);
    }
  };

  const onGrantTypeSelect = (grantType: GrantTypeEnum) => {
    setFieldValue('grantType', grantType);
    // setFieldValue('documentation', grantType === GrantTypeEnum.Babicka);
    submitForm();
  };

  const hideModal = (action: 'confirm' | 'cancel') => {
    if (action === 'confirm' && modalSetup?.onConfirm) {
      modalSetup?.onConfirm();
    } else if (action === 'cancel' && modalSetup?.onCancel) {
      modalSetup?.onCancel();
    }
    setModalSetup(null);
  };

  return (
    <>
      <h2 className="mb-4 sm:mb-6">Optimální řešení rekonstrukce</h2>
      <h4 className="mb-10 sm:mb-12">
        Vypočítali jsme z vašich odpovědí nejúspornější kombinaci. Jednotlivá
        opatření však můžete v návrhu dle libosti přidat nebo odebrat.
      </h4>
      {response && response.validSetting ? (
        <>
          <GrantTypeSwitcher
            isBabickaEligible={response.isBabickaEligible}
            onGrantTypeSelect={onGrantTypeSelect}
            toggleSetting={toggleSetting}
          />

          <div
            className={classNames('hidden sm:block space-y-14', {
              'mb-7': bothGrantsIneligible,
              'mb-14': !bothGrantsIneligible,
            })}
          >
            <div className={'grid sm:grid-cols-2 gap-4'}>
              <RatingChange response={response} />
              <SavingsResult
                saving={values.expenses ? response.total.saving : null}
              />
            </div>

            <MeasuresTable
              response={response}
              grantType={values.grantType}
              setting={setting}
              toggle={onToggle}
            />
          </div>
          <div className="sm:hidden mb-16 space-y-16">
            <RatingChangeMobile response={response} />
            <SavingsResult
              saving={values.expenses ? response.total.saving : null}
            />
            <MeasuresTableMobile
              response={response}
              setting={setting}
              grantType={values.grantType}
              toggle={onToggle}
            />
            <MeasureMobile
              {...response.total}
              bigHeader
              disabledGrant={bothGrantsIneligible}
            >
              <h3 className="text-white">Celkem</h3>
            </MeasureMobile>
          </div>

          {bothGrantsIneligible && (
            <MessageBox
              text="Je nám líto, ale dotaci nelze poskytnout. Zřejmě nesplňujete základní podmínky."
              severity="warning"
            />
          )}

          <div className="flex flex-col sm:flex-row items-center gap-4 mt-8 sm:mt-12">
            <img src={InfoCircle} alt="Info" />
            <span className="text-center sm:text-left">
              Jedná se o expertní odhad investičních nákladů a dotace od Centra
              pasivního domu. Finální cenu stanoví realizační firma.
            </span>
          </div>

          <div className="flex lg:hidden flex-col sm:flex-row mt-16 ml-auto sm:mt-14 sm:w-2/3">
            <div className="card border border-headline-color sm:self-start sm:mr-8">
              <div className="card-body p-5">
                <h6>
                  Nebojte, o výsledky nepřijdete. Na konci dotazníku si je
                  můžete poslat na e-mail.
                </h6>
              </div>
            </div>
            <div className="flex sm:block justify-end relative">
              <img
                className="sm:hidden absolute right-16 top-0"
                src={BubbleConnectionVertical}
                alt="Says gekon"
              />
              <img
                className="hidden sm:block absolute -left-8 top-0"
                src={BubbleConnectionHorizontal}
                alt="Says gekon"
              />
              <img
                className="w-1/2 sm:w-auto mt-8 sm:mt-0"
                src={Gecko}
                alt="Gekon"
              />
            </div>
          </div>
          <InvalidCombinationModal
            content={modalSetup}
            visible={!!modalSetup}
            hide={hideModal}
          />
          {props.isLoading && <Loading loadingText={LOADING_TEXT_COMPUTING} />}
        </>
      ) : null}
    </>
  );
}

export default ResultsStep;

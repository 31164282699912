import axios, { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { trackLeadFormSent } from 'helpers/analytics';
import { DEFAULT_PHONE_NUMBER, leadFormValidationSchema } from 'helpers/form';
import { ReactNode } from 'react';
import { Reasons } from 'shared/reasons';
import { ContactDataNoPartner, LeadData, LeadOptionalData } from 'shared/types';
import { useAppStore } from 'stores/appStore';

interface LeadFormProviderProps {
  children: ReactNode;
  reason: Reasons;
  additionalData?: LeadOptionalData;
  handleSubmit?: (values: Partial<ContactDataNoPartner>) => Promise<void>;
  setLoadingText: (text?: string) => void;
  setSent: (sent: boolean) => void;
  setError: (error?: AxiosError) => void;
}

export const LeadFormProvider = (props: LeadFormProviderProps) => {
  const appStore = useAppStore();
  const tel =
    (appStore.configuratorData as Partial<ContactDataNoPartner>)?.tel ||
    DEFAULT_PHONE_NUMBER;

  async function handleSubmit(values: Partial<ContactDataNoPartner>) {
    const leadData: LeadData = {
      reason: props.reason,
      tel: values.tel as string,
      ...props.additionalData,
    };

    props.setLoadingText();

    // If handleSubmit is provided, use it instead of default behavior
    return (
      props.handleSubmit
        ? props.handleSubmit(values)
        : axios.post('/send-lead', leadData).then(() => {
            appStore.updateConfiguratorData(values);
            appStore.completeStep(3);

            trackLeadFormSent(props.reason === Reasons.Financing);
          })
    )
      .then(() => props.setSent(true))
      .catch((error) => {
        props.setError(error);
      })
      .finally(() => {
        props.setLoadingText(undefined);
      });
  }

  const formik = useFormik({
    initialValues: {
      partner: false,
      tel,
      agreement: false,
    },
    onSubmit: handleSubmit,
    validationSchema: leadFormValidationSchema,
  });

  return <FormikProvider value={formik}>{props.children}</FormikProvider>;
};

import { measureAdditionalInfo } from 'shared/measure';
import { MeasureKey } from 'shared/types';

export type ResultModalContent = {
  headline?: string;
  text?: string | JSX.Element;
  confirmText: string;
  confirmTextMobile?: string;
  cancelText: string;
};

export type ResultModalSetting =
  | (ResultModalContent & { onConfirm?: () => void; onCancel?: () => void })
  | null;

export function getSourceSwapModalContent(key: MeasureKey): ResultModalContent {
  const from = key === 'heatPump' ? 'kotel' : 'tepelné čerpadlo';
  const to = key === 'heatPump' ? 'tepelné čerpadlo' : 'kotel';

  return {
    headline: `Už máte zapnutý zdroj tepla “${from}”. Chcete ho změnit na “${to}”?`,
    confirmText: `Ano, změnit na ${to}`,
    cancelText: `Ne, ponechat ${from}`,
  };
}

export function getWallInsulationModalContent(): ResultModalContent {
  return {
    headline:
      'Pozor! Některá opatření bez jiných nedávají smysl. Stěnami odchází z domu cca 70% všeho tepla.',
    text: 'Pokud budete měnit okna za kvalitnější, ale nezateplíte, bude vám teplo z domu stále unikat. Podobně nesmyslné je nezateplit a pořídit si tepelné čerpadlo.',
    confirmText: 'Ponechat opatření v kalkulaci',
    confirmTextMobile: 'Ponechat opatření',
    cancelText: 'Odstanit opatření',
  };
}

export function getBabickaModalContent(key: MeasureKey): ResultModalContent {
  return {
    text: (
      <>
        Pozor! Pokud vypnete{' '}
        <em>{measureAdditionalInfo[key].fourthFallLabel}</em>, nesplníte
        podmínku optimálního zateplení pro dotaci <em>Oprav dům po babičce.</em>
      </>
    ),
    confirmText: `Ponechat ${measureAdditionalInfo[key].fourthFallLabel} v kalkulaci`,
    cancelText: 'Odebrat opatření',
  };
}

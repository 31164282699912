import { ErrorEvent } from '@sentry/react';

const IGNORED_ERRORS = [
  'Failed to load script https://assets.adobedtm.com',
  'TypeError: Cannot read properties of undefined (reading \'clientHeight\')'
];

export function filterOutSentryEvents(event: ErrorEvent): ErrorEvent {
  if (event.exception?.values) {
    event.exception.values = event.exception.values.filter(
      (exception) => exception.value && !IGNORED_ERRORS.includes(exception.value)
    );
  }

  return event;
}
import { useFormikContext } from 'formik';
import { FormData } from 'shared/types';
import classNames from 'classnames';
import ValidationSuccess from 'assets/icons/validation-success.svg';
import ValidationError from 'assets/icons/validation-error.svg';

interface SelectProps {
  name: keyof FormData;
  options: { [key: string]: string } | string[];
  label?: string;
  placeholder?: string;
}

function Select(props: SelectProps) {
  const { values, handleChange, handleBlur, touched, errors } = useFormikContext<FormData>();
  const placeholder = props.placeholder || 'Vyberte';
  const value: any = values[props.name] || placeholder;
  const error = touched[props.name] && errors[props.name];
  const isValid = !error && touched[props.name];
  const options = Array.isArray(props.options)
    ? props.options.map((option) => ({ value: option, label: option }))
    : Object.keys(props.options).map((option) => ({
        value: option,
        label: (props.options as { [key: string]: string })[option],
      }));

  return (
    <div className="form-control w-full">
      {props.label && (
        <label className="label">
          <span className="label-text">{props.label}</span>
        </label>
      )}
      <div className="flex items-center space-x-2.5">
        <select
          className={classNames('select', 'select-bordered', 'flex-1', 'w-full', 'max-w-md', {
            'select-error': error,
          })}
          name={props.name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option disabled>{placeholder}</option>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        {isValid && <img src={ValidationSuccess} alt="Valid" />}
        {error && <img src={ValidationError} alt="Invalid" />}
      </div>
      {error && (
        <label className="label">
          <span className="label-text-alt text-sm text-error">{error}</span>
        </label>
      )}
    </div>
  );
}

export default Select;

import ChevronDown from 'assets/icons/chevron-down-blue.svg';
import Button from 'components/Button';
import { downloadPdf } from 'helpers/downloadPdf';
import { FormData, MeasureSetting } from 'shared/types';

interface DownloadPdfButtonProps {
  values: FormData;
  setting: MeasureSetting;
}
export const DownloadPdfButton = (props: DownloadPdfButtonProps) => (
  <Button
    type="outlined"
    className="btn-primary"
    icon={ChevronDown}
    label="Stáhnout PDF"
    onClick={() => {
      downloadPdf(props.values, props.setting);
    }}
  ></Button>
);

import classNames from 'classnames';
import { useMemo } from 'react';

// enum MessageSeverity {
//   Error = 'error',
//   Warning = 'warning',
//   Info = 'info',
//   Success = 'success',
// }
type MessageSeverity = 'error' | 'warning' | 'info' | 'success';

type MessageBoxProps =
  | { severity?: MessageSeverity; text: string; children?: never }
  | { severity?: MessageSeverity; children: React.ReactNode; text?: never };

const MessageBox = (props: MessageBoxProps) => {
  const severity = useMemo(() => {
    switch (props.severity || 'info') {
      case 'error':
        return {
          box: 'bg-red-100 border-t-4 border-red-500 text-red-900',
          icon: 'text-red-500',
        };
      case 'warning':
        return {
          box: 'bg-orange-100 border-t-4 border-orange-300 text-orange-900',
          icon: 'text-orange-500',
        };
      case 'success':
        return {
          box: 'bg-green-100 border-t-4 border-green-300 text-green-900',
          icon: 'text-green-500',
        };
      case 'info':
        return {
          box: 'bg-blue-100 border-t-4 border-blue-300 text-blue-900',
          icon: 'text-blue-500',
        };
    }
  }, [props.severity]);
  return (
    <div className={classNames('px-4 py-3', severity.box)} role="alert">
      {props.children ? (
        props.children
      ) : (
        <div className="flex items-center">
          <div className="py-1">
            <svg
              className={classNames('fill-current h-6 w-6 mr-4', severity.icon)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div className="flex-1">
            <p className="text-sm font-semibold">{props.text}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageBox;

import Switcher from 'components/form/Switcher';
import Tooltip from 'components/Tooltip';
import { useFormikContext } from 'formik';
import SwitchersExplanation from 'pages/configurator/steps/result/components/SwitchersExplanation';
import { HIDE_SAVINGS } from 'shared/global';
import { measureAdditionalInfo } from 'shared/measure';
import { formatNumber } from 'shared/number';
import {
  FormData,
  GrantTypeEnum,
  MeasureKey,
  MeasureSetting,
  ResponseData,
} from 'shared/types';

interface MeasuresTableProps {
  setting: MeasureSetting;
  toggle: (key: MeasureKey) => void;
  response: ResponseData;
  grantType?: GrantTypeEnum;
}

function MeasuresTable(props: MeasuresTableProps) {
  const { response, setting, toggle } = props;
  const { values } = useFormikContext<FormData>();
  const isBabickaEligible =
    values.permanentResidence &&
    values.complexReconstruction &&
    values.anotherPermanentResidence === false;
  const bothGrantsIneligible =
    values.permanentResidence === false ||
    values.anotherPermanentResidence === true;

  return (
    <div>
      <div className="relative z-0 mb-6">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>Možná opatření</th>
              <th className="text-right">
                Cena
                <div>
                  <span className="font-normal text-sm mt-1.5">Kč</span>
                </div>
              </th>
              <th className="text-right">
                Dotace
                <div className="flex justify-between items-end">
                  <Tooltip
                    className="ml-1"
                    html="Orientační částka, kterou lze z dotačního programu získat. Jde o výpočet z dotačního programu Nová Zelená Úsporám."
                  />
                  <span className="font-normal text-sm mt-1.5">Kč</span>
                </div>
              </th>
              {!HIDE_SAVINGS && (
                <th className="text-right">
                  Úspora měsíčně
                  <div className="flex justify-between items-end">
                    <Tooltip
                      className="ml-1"
                      html="Měsíční úsporu počítáme z rozdílu provozních nákladů před a po rekonstrukci."
                    />
                    <span className="font-normal text-sm mt-1.5">Kč</span>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(measureAdditionalInfo).map((m) => {
              const measureKey = m as MeasureKey;
              const measure = response.validSetting
                ? response.measures[measureKey]
                : { price: 0, grant: 0, saving: 0 };
              const additionalInfo = measureAdditionalInfo[measureKey];

              return (
                <tr key={measureKey}>
                  <th>
                    <Switcher
                      label={additionalInfo.label}
                      checked={setting[measureKey]}
                      onChange={() => toggle(measureKey)}
                      tooltip={additionalInfo.tooltip}
                      disabled={
                        additionalInfo.uneligible && values.grantType
                          ? additionalInfo.uneligible?.includes(
                              values.grantType,
                            )
                          : false
                      }
                    />
                  </th>
                  <td className="text-right">
                    {setting[measureKey] ? formatNumber(measure.price) : '–'}
                  </td>
                  <td className="text-right">
                    {setting[measureKey] && !bothGrantsIneligible
                      ? formatNumber(measure.grant)
                      : '–'}
                  </td>
                  {!HIDE_SAVINGS && (
                    <td className="text-right bg-lightblue">
                      {formatNumber(measure.saving)}
                    </td>
                  )}
                </tr>
              );
            })}
            <tr>
              <td>
                <div className="flex items-center space-x-2 py-1.5">
                  <span>Kombinační bonus</span>
                  <Tooltip html="50 000 Kč u Babičky/30 000 Kč u NZU Light za kombinaci oblasti podpory A – zateplení a oblasti podpory C - zdroje energie/výměna zdrojů tepla" />
                </div>
              </td>
              <td className="text-right">–</td>
              <td className="text-right">
                {bothGrantsIneligible
                  ? '–'
                  : response.validSetting
                    ? formatNumber(response.combinativeBonus)
                    : 0}
              </td>
            </tr>
            {isBabickaEligible && values.children && (
              <tr>
                <td>
                  <div className="flex items-center space-x-2 py-1.5">
                    <span>Bonus na dítě</span>
                    <Tooltip html="Podpora cílí na mladé rodiny, proto žadatelé obdrží rodinný bonus ve výši 50 tisíc korun na každé nezaopatřené dítě." />
                  </div>
                </td>
                <td className="text-right">–</td>
                <td className="text-right">
                  {response.validSetting
                    ? props.grantType === GrantTypeEnum.Babicka
                      ? formatNumber(response.childrenBonus)
                      : '–'
                    : '–'}
                </td>
                {!HIDE_SAVINGS && (
                  <td className="text-right bg-lightblue">0</td>
                )}
              </tr>
            )}
            <tr>
              <td>
                <div className="flex items-center space-x-2 py-1.5">
                  <span>+ Stavební úpravy</span>
                  <Tooltip
                    html="Stavební úpravy tvoří obvykle 15 % z celkové ceny rekonstrukce.
                        Jedná se o menší rezervu na stavební úpravy, které nesouvisí s úspornými opatřeními,
                        nejdou předem odhadnout a vyplynou zpravidla při rekonstrukci."
                  />
                </div>
              </td>
              <td className="text-right">
                {response.validSetting
                  ? formatNumber(response.constructionPrice)
                  : 0}
              </td>
              <td className="text-right">–</td>
              {!HIDE_SAVINGS && <td className="text-right bg-lightblue">0</td>}
            </tr>
            <tr>
              <th className="py-3.5">
                <h6>Celkem</h6>
              </th>
              <th className="text-right">
                <h6>
                  {response.validSetting
                    ? formatNumber(response.total.price)
                    : 0}
                </h6>
              </th>
              <th className="text-right">
                <h6>
                  {bothGrantsIneligible
                    ? '–'
                    : response.validSetting
                      ? formatNumber(response.total.grant)
                      : 0}
                </h6>
              </th>
              {!HIDE_SAVINGS && (
                <th className="text-right bg-lightblue">
                  <h6>
                    {response.validSetting
                      ? formatNumber(response.total.saving)
                      : 0}
                  </h6>
                </th>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <SwitchersExplanation />
    </div>
  );
}

export default MeasuresTable;

import { PictureSelectContext } from 'components/form/PictureSelect';
import { useContext } from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import './PictureOption.scss';

interface PictureOptionProps {
  caption: string;
  value: string | number;
  src: string;
  srcHover: string;
  srcSelected: string;
}

function PictureOption(props: PictureOptionProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { selected, onSelect } = useContext(PictureSelectContext);
  const isSelected = selected === props.value;

  return (
    <div
      className={classNames('picture-option', {
        selected: isSelected,
      })}
      onClick={() => onSelect(props.value)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={isSelected ? props.srcSelected : isHovered ? props.srcHover : props.src} alt={props.caption} />
      <p>{props.caption}</p>
    </div>
  );
}

export default PictureOption;

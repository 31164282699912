import Block from 'components/Block';
import Select from 'components/form/Select';
import { HouseAgeEnum } from 'shared/types';

const HouseAgeOptions = {
  [HouseAgeEnum.Before1964]: 'před 1964',
  [HouseAgeEnum.Since1965]: '1965–1976',
  [HouseAgeEnum.Since1977]: '1977–1990',
  [HouseAgeEnum.Since1991]: '1991–2001',
  [HouseAgeEnum.Since2002]: 'po 2002',
};

function Step1() {
  return (
    <>
      <h2>Pojďme to spočítat</h2>
      <Block
        headline="Kdy byl postaven váš dům?"
        copy="Podle toho, kdy byl dům postaven, odvodíme specifické materiály a jejich tepelnou propustnost. Pokud si nejste jistí, zkuste období alespoň odhadnout."
      >
        <Select options={HouseAgeOptions} placeholder="Vyberte rok" name="houseAge" />
      </Block>
    </>
  );
}

export default Step1;

import GrantTypeStep, { GrantFormData } from 'components/GrantTypeStep';
import Step2 from 'pages/grants/components/Step2';
import Step3 from 'pages/grants/components/Step3';
import PageLayout from 'components/layout/PageLayout';
import { useAppStore } from 'stores/appStore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { getTouchedFromErrors } from 'helpers/form';
import { trackStep } from 'helpers/analytics';
import { getLink, RouteEnum } from 'helpers/routes';
import { grantTips } from 'shared/textations';

const steps = { 1: 'výběr dotace', 2: 'souhrn dotace', 3: 'průběh dotace' };

function Grants() {
  const [actualStep, setActualStep] = useState(1);
  const store = useAppStore();
  const navigate = useNavigate();
  const { values, setTouched, validateForm } = useFormikContext<GrantFormData>();

  const goToNextStep = async () => {
    if (actualStep === 1) {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        setActualStep(actualStep + 1);
        store.updateConfiguratorData(values);
      } else {
        setTouched(getTouchedFromErrors(errors));
        return false;
      }
    }

    if (actualStep === 2) {
      setActualStep(actualStep + 1);
    }

    if (actualStep === 3) {
      store.completeStep(4);
      navigate(getLink(RouteEnum.HomePage));
      return true;
    }

    trackStep(actualStep + 1);
    return true;
  };

  const goToPreviousStep = () => {
    if (actualStep === 1) {
      navigate(getLink(RouteEnum.HomePage));
      return;
    }

    setActualStep(actualStep - 1);
    trackStep(actualStep - 1);
  };

  return (
    <PageLayout
      actualStep={actualStep}
      steps={steps}
      tips={grantTips}
      tipHeadline="Tip k dotacím"
      nextStepLabel={actualStep === 3 ? 'Na rozcestník' : 'Pokračovat'}
      goToNextStep={goToNextStep}
      previousStepLabel="Zpět"
      goToPreviousStep={goToPreviousStep}
    >
      {actualStep === 1 && <GrantTypeStep skipChildren />}
      {actualStep === 2 && <Step2 />}
      {actualStep === 3 && <Step3 />}
    </PageLayout>
  );
}

export default Grants;

import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { PropsWithChildren } from 'react';

interface BlockProps extends PropsWithChildren {
  headline?: string;
  copy?: string;
  tooltip?: string;
  className?: string;
}

function Block(props: BlockProps) {
  return (
    <div className={classNames('block', props.className)}>
      {props.headline && (
        <h4 className="flex items-center mb-2">
          {props.headline}
          {props.tooltip && <Tooltip html={props.tooltip} className="ml-2" />}
        </h4>
      )}
      {props.copy && <p className="mb-2 sm:mb-4">{props.copy}</p>}
      {props.children}
    </div>
  );
}

export default Block;

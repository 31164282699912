import Block from 'components/Block';
import Input from 'components/form/Input';
import Select from 'components/form/Select';
import Radio from 'components/form/Radio';
import { useFormikContext } from 'formik';
import { FormData } from 'shared/types';

function Step2() {
  const { values } = useFormikContext<FormData>();

  return (
    <>
      <h2>Teď nás zajímají jeho rozměry</h2>
      <Block
        headline="Jakou plochu má půdorys vašeho domu?"
        copy="Nevytápěná přístavba (např. garáž přiléhající k domu, stodola a podobně) se nezapočítává."
        tooltip="Pokud plochu přesně nevíte, zkuste ji spočítat pomocí rozměrů jednotlivých místností."
      >
        <Input name="area" type="number" placeholder="0" unit="m²" />
      </Block>
      <Block
        headline="Kolik má dům podlaží?"
        copy="Čím vyšší dům, tím větší plocha stěn a tím větší náklady na vytápění."
      >
        <Select placeholder="0" options={['1', '2', '3', '4']} name="floors" />
      </Block>
      <Block headline="Máte sklep?">
        <Radio inline name="basement" />
      </Block>
      {values.basement && (
        <Block
          headline="Je sklep vytápěný?"
          copy="Pokud ano, můžete uvažovat o jeho zateplení. Teplo totiž může unikat i do okolní půdy."
        >
          <Radio inline name="heatedBasement" />
        </Block>
      )}
    </>
  );
}

export default Step2;

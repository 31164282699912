import { FormData, GrantTypeEnum } from './types';

export const getGrantTypes = (
  values: FormData,
): {
    [key in GrantTypeEnum]: {
      label: string;
      attributes: string[];
      conditions?: string[];
      available?: boolean;
    };
  } => ({
    [GrantTypeEnum.Babicka]: {
      label: 'Oprav dům po babičce',
      attributes: [
        'Dotace určená pro komplexní renovaci (obsahuje zateplení domu)',
        'Je vyplácená před realizací',
        'Můžete získat finanční bonus na nezaopatřené dítě'
      ],
      conditions: [
        'Podmínkou je optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy B.'
      ],
      available:
        values.permanentResidence === undefined ||
          values.complexReconstruction === undefined ||
          values.anotherPermanentResidence === undefined
          ? undefined
          : values.permanentResidence && values.complexReconstruction && values.anotherPermanentResidence === false,
  },
  [GrantTypeEnum.NZUL]: {
    label: "Nová zelená úsporám Light",
    attributes: [
      'Dotace je určená pro dílčí renovaci',
      'Je vyplácená před realizací'
    ],
    available:
      values.permanentResidence === undefined ||
        values.complexReconstruction === undefined
        ? undefined
        : values.permanentResidence && values.anotherPermanentResidence === false,
  }
});

import Check from 'assets/icons/check.svg';
import House from 'assets/icons/house.svg';
import Block from 'components/Block';
import Checkbox from 'components/form/Checkbox';
import Input from 'components/form/Input';
import { useFormikContext } from 'formik';
import { DEFAULT_PHONE_NUMBER } from 'helpers/form';
import Recap from 'pages/configurator/components/Recap';
import LeadFormCard from 'pages/configurator/steps/contact/LeadFormCard';
import { lowestPossiblePay } from 'shared/financing';
import { measureAdditionalInfo } from 'shared/measure';
import {
  Loan,
  MeasureKey,
  MeasureSetting,
  ValidatedFormData,
} from 'shared/types';
import { useAppStore } from 'stores/appStore';

interface ContactStepProps {
  loans: Loan[];
  total: number;
  saving: number;
  setting: MeasureSetting;
}

function ContactStep(props: ContactStepProps) {
  const { values } = useFormikContext<ValidatedFormData>();
  const { loanLength, partner } = values;
  const loanAmount = Math.max(props.total - values.ownSavings, 0);
  const savedData = useAppStore().configuratorData;
  const telAlreadyEntered =
    savedData?.partner === false && savedData?.tel !== DEFAULT_PHONE_NUMBER;

  return (
    <div>
      <h2>Souhrn {partner ? 'klienotovy' : 'vaší'} rekonstrukce</h2>
      <Recap
        total={props.total}
        loanAmount={loanAmount}
        saving={props.saving}
        pay={lowestPossiblePay(props.loans, loanAmount, loanLength)}
      />
      <div className="bg-lightblue p-6 mb-6">
        <h3 className="flex items-center space-x-4">
          <img src={House} alt="House config" />
          <span>
            Co {partner ? 'bude partner' : 'budeme'} v rámci rekonstrukce dělat?
          </span>
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-4 mb-10">
          {Object.keys(props.setting)
            .filter((key) => props.setting[key as MeasureKey])
            .map((key) => (
              <div className="flex items-center space-x-2" key={key}>
                <img src={Check} alt="Measure" width={13.3} height={8} />
                <span>{measureAdditionalInfo[key as MeasureKey].label}</span>
              </div>
            ))}
        </div>
        <Block
          headline={
            partner
              ? 'Odešlete návrh rekonstrukce na e-mail klienta'
              : 'Odešleme vám návrh rekonstrukce na e-mail'
          }
          copy={`${partner ? 'Použije' : 'Použijete'} ho ke konzultaci s odborníky na rekonstrukci. Spolu s ním ${
            partner ? 'dostane' : 'dostanete'
          } pdf s modelovými příklady jednotlivých úvěrů.`}
        >
          <Input
            label={partner ? 'E-mail klienta' : undefined}
            name="email"
            type="email"
            placeholder="muj@email.cz"
            className="mt-6"
          />
        </Block>
      </div>
      {(partner || !telAlreadyEntered) && <LeadFormCard />}
      {!partner && (
        <Checkbox
          name="agreement"
          label={
            <>
              Potvrzuji, že jsem se seznámil/a se způsobem{' '}
              <a
                href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                zpracování osobních údajů
              </a>
            </>
          }
          className="mt-8 sm:mt-12"
        />
      )}
    </div>
  );
}

export default ContactStep;

import Gecko from 'assets/gecko.png';
import BubbleConnection from 'assets/bubble-connection.svg';
import classNames from 'classnames';

// Desktop: Showed for current step in the right panel, hidden if not defined
// Mobile/tablet:
//   - by clicking "next" in navigation
//      1. switches to the next step
//      2. shows the tip for the last visible step (= current step - 1) on fullscreen (by turning mobileTipVisible on)
//      3. shows fake navigation, where "next" button turns mobileTipVisible off
//   - changing screen to desktop also turns mobileTipVisible off,
//      so changing screen back on mobile tip doesn't cover the form
//   - when no tip for the current step is defined, "next" button switches to the next step,
//      but mobileTipVisible stays turned off

interface TipProps<T extends number> {
  tips?: { [key in T]: string | string[] | null };
  tipHeadline: string;
  step: T;
  mobileTipVisible?: boolean;
}

function Tip<T extends number>(props: TipProps<T>) {
  const renderTip = (tip: string | string[] | null) =>
    tip === null ? null : typeof tip === 'string' ? (
      <p>{tip}</p>
    ) : (
      <div className="space-y-2">{tip?.map((t, i) => <p key={i}>{t}</p>)}</div>
    );

  if (!props.tips) return null;

  const tip = props.tips[props.step];
  const stepMobile = (props.step - (props.mobileTipVisible ? 1 : 0)) as T;
  const tipMobile = props.tips[stepMobile];

  return (
    <div
      className={classNames('px-4', 'py-8', {
        hidden: !props.mobileTipVisible,
        'lg:block': tip,
        'lg:hidden': !tip,
      })}
    >
      <h3 className="lg:hidden mb-4 text-white">{props.tipHeadline}</h3>
      <div className="card border bg-white text-link">
        <div className="card-body p-5">
          <div className="lg:hidden">{renderTip(tipMobile)}</div>
          <div className="hidden lg:block">{renderTip(tip)}</div>
        </div>
      </div>
      <div className="relative flex flex-col items-end">
        <img className="absolute top-0 right-1/3" src={BubbleConnection} alt="Says gekon" />
        <img className="mt-8 w-1/2" src={Gecko} alt="Gekon" />
      </div>
    </div>
  );
}

export default Tip;

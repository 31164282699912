import { createContext, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { FormData } from 'shared/types';
import ValidationError from 'assets/icons/validation-error.svg';

interface PictureSelectProps extends PropsWithChildren {
  label?: string;
  name: keyof FormData;
  cols?: number;
}

export const PictureSelectContext = createContext<{
  selected?: string | number;
  onSelect: (value: string | number) => void;
  error?: string | boolean;
}>({
  selected: undefined,
  onSelect: () => {},
});

function PictureSelect(props: PictureSelectProps) {
  const { values, touched, errors, setFieldValue } = useFormikContext<FormData>();
  const value = values[props.name];
  const error = touched[props.name] && errors[props.name];

  const classes = classNames('grid', 'gap-2', 'sm:gap-5', 'grid-cols-1', 'my-1', {
    // TODO Do it in a more intelligent way
    // The classes has to be fully predefined, otherwise tailwind won't precompile them
    'sm:grid-cols-2': props.cols !== 2,
    'sm:grid-cols-3': props.cols === 3,
  });

  return (
    <>
      {props.label && (
        <h4 className="flex items-center mb-2 gap-2">
          {props.label}
          {error && <img src={ValidationError} alt="Error" />}
        </h4>
      )}
      <div className="form-control w-full">
        <div className={classes}>
          <PictureSelectContext.Provider
            value={{
              selected: value as string | number | undefined,
              onSelect: (value: string | number) => setFieldValue(props.name, value),
              error,
            }}
          >
            {props.children}
          </PictureSelectContext.Provider>
        </div>
        {error && (
          <label className="label">
            <span className="text-error">{error}</span>
          </label>
        )}
      </div>
    </>
  );
}

export default PictureSelect;

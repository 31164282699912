import Switcher from 'components/form/Switcher';
import Tooltip from 'components/Tooltip';
import { useFormikContext } from 'formik';
import MeasureMobile from 'pages/configurator/steps/result/components/MeasureMobile';
import SwitchersExplanation from 'pages/configurator/steps/result/components/SwitchersExplanation';
import { measureAdditionalInfo } from 'shared/measure';
import {
  FormData,
  GrantTypeEnum,
  MeasureKey,
  MeasureSetting,
  ResponseData,
} from 'shared/types';

interface MeasuresTableMobileProps {
  setting: MeasureSetting;
  toggle: (key: MeasureKey) => void;
  response: ResponseData;
  grantType?: GrantTypeEnum;
}

function MeasuresTableMobile(props: MeasuresTableMobileProps) {
  const { values } = useFormikContext<FormData>();
  const bothGrantsIneligible =
    values.permanentResidence === false ||
    values.anotherPermanentResidence === true;

  return props.response.validSetting ? (
    <div>
      <div className="bg-primary px-4 py-5 mb-5">
        <h3 className="text-white">Možná opatření</h3>
      </div>

      {Object.keys(measureAdditionalInfo).map((m) => {
        const measureKey = m as MeasureKey;
        const additionalInfo = measureAdditionalInfo[measureKey];
        const tooltip = additionalInfo.tooltip;

        return (
          props.response.validSetting && (
            <MeasureMobile
              {...props.response.measures[measureKey]}
              disabled={!props.setting[measureKey]}
              disabledGrant={bothGrantsIneligible}
              key={measureKey}
              className="mb-5"
            >
              <div className="flex justify-between">
                <h5 className="flex items-center gap-2.5">
                  {additionalInfo.label}
                  {tooltip && <Tooltip html={tooltip} />}
                </h5>
                <Switcher
                  checked={props.setting[measureKey]}
                  onChange={() => props.toggle(measureKey)}
                  disabled={
                    additionalInfo.uneligible && values.grantType
                      ? additionalInfo.uneligible?.includes(values.grantType)
                      : false
                  }
                />
              </div>
            </MeasureMobile>
          )
        );
      })}
      <MeasureMobile
        price={props.response.combinativeBonus}
        className="mb-5"
        disabled={bothGrantsIneligible}
      >
        <h5 className="flex items-center space-x-2.5 py-1.5">
          <span>Kombinační bonus</span>
          <Tooltip html="50 000 Kč u Babičky/30 000 Kč u NZU Light za kombinaci oblasti podpory A – zateplení a oblasti podpory C - zdroje energie/výměna zdrojů tepla" />
        </h5>
      </MeasureMobile>
      {props.grantType === GrantTypeEnum.Babicka && values.children && (
        <MeasureMobile price={props.response.childrenBonus} className="mb-5">
          <h5 className="flex items-center space-x-2.5 py-1.5">
            <span>Bonus na dítě</span>
            <Tooltip html="Podpora cílí na mladé rodiny, proto žadatelé obdrží rodinný bonus ve výši 50 tisíc korun na každé nezaopatřené dítě." />
          </h5>
        </MeasureMobile>
      )}
      <MeasureMobile price={props.response.constructionPrice} className="mb-5">
        <h5 className="flex items-center space-x-2.5 py-1.5">
          <span>+ Stavební úpravy</span>
          <Tooltip
            html="Stavební úpravy tvoří obvykle 15 % z celkové ceny rekonstrukce.
                        Jedná se o menší rezervu na stavební úpravy, které nesouvisí s úspornými opatřeními,
                        nejdou předem odhadnout a vyplynou zpravidla při rekonstrukci."
          />
        </h5>
      </MeasureMobile>
      <SwitchersExplanation />
    </div>
  ) : null;
}

export default MeasuresTableMobile;

import { useEffect } from 'react';

export function usePageTitle(title: string): void {
  useEffect(() => {
    if (title) {
      const originalTitle = document.title;
      document.title = `${title} | Úsporné bydlení`;

      return () => {
        document.title = originalTitle;
      };
    }
  }, [title]);
}

import { useFormikContext } from 'formik';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';

import IconPhoneDone from 'assets/icons/done-phone.svg';
import IconPhone from 'assets/icons/phone.svg';
import axios from 'axios';
import Button from 'components/Button';
import LeadForm from 'components/form/LeadForm/LeadForm';
import Modal from 'components/Modal/Modal';
import { trackLeadFormSent } from 'helpers/analytics';
import { Reasons } from 'shared/reasons';
import { ContactDataNoPartner, LeadData } from 'shared/types';
import { useAppStore } from 'stores/appStore';
import './CallBack.scss';

export const CALL_US_COOKIE_NAME = 'callback-lead';
export const POPUP_SNOOZE = 'callback-snooze';
const DEFAULT_TIMEOUT = 30000;

interface CallBackProps {
  step: number;
}

export const CallBack = (props: CallBackProps) => {
  const [showModal, setShowModal] = useState(false);
  const [buttonUsed, setButtonUsed] = useState(false);

  const leadSentCookie = Cookies.get(CALL_US_COOKIE_NAME);
  const isSnoozed = sessionStorage.getItem(POPUP_SNOOZE);

  const appStore = useAppStore();

  const popupTimer = useRef<NodeJS.Timeout | null>(null);
  // formik stuff
  const formik = useFormikContext();
  const previousValues = useRef(formik.values);
  const previousStep = useRef(props.step);

  // reset popup timer
  const resetPopupTimer = useCallback(
    (stop?: boolean) => {
      let timer = null;
      if (popupTimer.current) {
        clearTimeout(popupTimer.current);
      }

      if (!stop) {
        timer = setTimeout(() => {
          setShowModal(true);
        }, DEFAULT_TIMEOUT);
      }
      popupTimer.current = timer;
    },
    [popupTimer],
  );

  // clear timer on popup close and set cookie to not start timer again
  const handlePopupClose = () => {
    if (!buttonUsed) {
      sessionStorage.setItem(POPUP_SNOOZE, 'true');
    } else {
      resetPopupTimer(true);
    }
    setShowModal(false);
  };

  const handleOpenPopup = () => {
    if (popupTimer.current) {
      resetPopupTimer(true);
    }
    setShowModal(true);
    setButtonUsed(true);
  };

  // reset popup timer on values change
  useEffect(() => {
    const prevValues = JSON.stringify(previousValues.current);
    const values = JSON.stringify(formik.values);
    const shouldReset =
      !leadSentCookie &&
      !isSnoozed &&
      (prevValues !== values || props.step !== previousStep.current);

    if (shouldReset) {
      resetPopupTimer();
    }

    previousValues.current = formik.values;
    previousStep.current = props.step;
  }, [
    props.step,
    formik.values,
    popupTimer,
    resetPopupTimer,
    leadSentCookie,
    isSnoozed,
  ]);

  // initial start
  useEffect(() => {
    const shouldInitialize =
      !leadSentCookie && !popupTimer.current && !isSnoozed;
    if (shouldInitialize) {
      resetPopupTimer();
    }
  }, [leadSentCookie, isSnoozed, popupTimer, resetPopupTimer]);

  // clear timer on unmount
  useEffect(() => {
    return () => {
      resetPopupTimer(true);
    };
  }, [resetPopupTimer]);

  const handleSubmit = async (values: Partial<ContactDataNoPartner>) => {
    resetPopupTimer(true);
    const leadData: LeadData = {
      reason: Reasons.CallBack,
      tel: values.tel as string,
    };

    return axios.post('/send-call-back', leadData).then(() => {
      const configuratorValues = { ...values, tel: values.tel };
      appStore.updateConfiguratorData(configuratorValues);
      Cookies.set(CALL_US_COOKIE_NAME, 'true', { expires: 1 });
      trackLeadFormSent(true);
    });
  };

  return leadSentCookie ? null : (
    <>
      <div className="CallBack">
        <div className="flex items-center gap-2 font-bold text-lg">
          <img src={IconPhone} alt="" width={40} />
          <span className="md:hidden sm:inline">Potřebuji poradit</span>
          <span className="hidden md:inline">
            Chcete se nezávazně poradit? Nechte nám na sebe kontant a my se vám
            ozveme.
          </span>
        </div>
        <Button
          onClick={handleOpenPopup}
          label={'Zavolejte mi'}
          icon={undefined}
          type="outlined"
          className="btn-secondary whitespace-nowrap"
        />
      </div>

      {showModal && (
        <Modal isOpened={showModal} onClose={handlePopupClose}>
          <LeadForm
            buttonLabel="Odeslat"
            buttonType="secondary"
            handleSubmit={handleSubmit}
            icon={IconPhoneDone}
            perex="Nechte nám své aktuální telefonní číslo a my vám co nejdříve zavoláme."
            reason={Reasons.CallBack}
            title="Zavolejte mi"
          />
        </Modal>
      )}
    </>
  );
};

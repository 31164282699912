// import { DimensionsAreaType } from "shared/types";

/*
1.
    (C35 * '2.' !B27)   vstupni/francouzske dvere atd [2]
  + (C37 * '2.' !B29)   mala okna  [0.64]
  + (C38 * '2.' !B30)   stredni okna [1.8]
  + (C39 * '2.' !B31)   velka okna  [4.5]
*/
type WindowsAreaType = {
  windowsSmall?: number;
  windowsMedium?: number;
  windowsLarge?: number;
  doors?: number;
};

type DimensionsAreaType = {
  windowsSmall: number;
  windowsMedium: number;
  windowsLarge: number;
  doors: number;
};

export const computeWindowsArea = (windows: WindowsAreaType, dimensions: DimensionsAreaType | undefined): number => {
  const { windowsSmall, windowsMedium, windowsLarge, doors } = windows;

  if (!dimensions) {
    return 0;
  }

  return (
    (doors !== undefined ? doors : 2) * dimensions.doors +
    (windowsSmall || 0) * dimensions.windowsSmall +
    (windowsMedium || 0) * dimensions.windowsMedium +
    (windowsLarge || 0) * dimensions.windowsLarge
  );
};

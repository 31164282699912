import Minus from 'assets/icons/minus.svg';
import Plus from 'assets/icons/plus.svg';
import { ValidatedFormData } from 'shared/types';
import { useFormikContext } from 'formik';
import './SliderWithInput.scss';

const MIN = 5;
const MAX = 30;

function SliderWithInput() {
  const { values, setFieldValue } = useFormikContext<ValidatedFormData>();
  const { loanLength } = values;

  const incrementLoanLength = () => {
    if (loanLength < MAX) setFieldValue('loanLength', +loanLength + 1);
  };

  const decrementLoanLength = () => {
    if (loanLength > MIN) setFieldValue('loanLength', loanLength - 1);
  };

  return (
    <div className="flex flex-col sm:flex-row items-start gap-4">
      <div className="flex flex-col flex-1 w-full pt-4">
        <input
          type="range"
          min={MIN}
          max={MAX}
          value={values.loanLength}
          onChange={(e) => setFieldValue('loanLength', e.target.value)}
          style={{
            backgroundSize: ((values.loanLength - MIN) * 100) / (MAX - MIN) + '% 100%',
          }}
        />
        <label className="label">
          <span className="label-text text-border-color">{MIN} let</span>
          <span className="label-text text-border-color">{MAX} let</span>
        </label>
      </div>
      <div className="flex items-center">
        <img src={Minus} alt="Minus" onClick={decrementLoanLength} className="mx-2 cursor-pointer" />
        <div className="w-16 border-2 border-border-color py-2 text-center font-bold">{values.loanLength} let</div>
        <img src={Plus} alt="Plus" onClick={incrementLoanLength} className="mx-2 cursor-pointer" />
      </div>
    </div>
  );
}

export default SliderWithInput;

import cycleIcon from 'assets/icons/cycle.svg';
import Button from 'components/Button';

const ConstantsNotLoaded = () => {
  return (
    <div
      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3"
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <svg
            className="fill-current h-6 w-6 text-teal-500 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="flex-1">
          <p className="font-bold mb-2">Ups, něco se pokazilo</p>
          <p className="text-sm">
            Bohužel se nepodařilo vše jak má a aplikace nemá potřebná data.
            <br />
            Zkuste přenačíst stránku nebo to zkuste později.
          </p>
        </div>
        <div className="py-1">
          <Button
            type="outlined"
            className="btn-primary"
            label="Přenačíst"
            icon={cycleIcon}
            direction="right"
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
    </div>
  );
};

export default ConstantsNotLoaded;

import axios from 'axios';
import { FormData, MeasureSetting, RequestData, ValidatedFormData } from 'shared/types';

export function downloadPdf(formData: FormData, measureSetting: MeasureSetting ) {
  const request: RequestData = {
    formData: formData as unknown as ValidatedFormData,
    measureSetting,
  };
  axios
    .post('/download-pdf', request, {
      responseType: 'blob',
    })
    .then((response) => {
      // Create a Blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf'); // Set filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error);
    });
}
import { AxiosError } from 'axios';
import GrantTypeStep from 'components/GrantTypeStep';
import { StepEnum } from 'helpers/steps';
import Step1 from 'pages/configurator/steps/1/Step1';
import Step2 from 'pages/configurator/steps/2/Step2';
import Step3 from 'pages/configurator/steps/3/Step3';
import Step4 from 'pages/configurator/steps/4/Step4';
import Step5 from 'pages/configurator/steps/5/Step5';
import ContactStep from 'pages/configurator/steps/contact/ContactStep';
import ErrorStep from 'pages/configurator/steps/error/ErrorStep';
import FinancingStep from 'pages/configurator/steps/financing/FinancingStep';
import ResultsStep from 'pages/configurator/steps/result/ResultsStep';
import { SavingsStep } from 'pages/configurator/steps/savings/SavingsStep';
import ThankYouStep from 'pages/configurator/steps/thank-you/ThankYouStep';
import { Loan, MeasureKey, MeasureSetting, ResponseData } from 'shared/types';

interface StepProps {
  step: StepEnum;
  error?: AxiosError;
  result?: ResponseData;
  loans?: Loan[];
  setting: MeasureSetting;
  toggleSetting: (key: MeasureKey) => void;
  isLoading: boolean;
}

function ConfiguratorStep(props: StepProps) {
  const total = props.result?.validSetting ? props.result.total.price : 0;
  const saving = props.result?.validSetting ? props.result.total.saving : 0;

  return props.error ? (
    <ErrorStep />
  ) : props.step === StepEnum.Step1 ? (
    <Step1 />
  ) : props.step === StepEnum.Step2 ? (
    <Step2 />
  ) : props.step === StepEnum.Step3 ? (
    <Step3 />
  ) : props.step === StepEnum.Step4 ? (
    <Step4 />
  ) : props.step === StepEnum.Step5 ? (
    <Step5 setting={props.setting} toggleSetting={props.toggleSetting} />
  ) : props.step === StepEnum.GrantType ? (
    <GrantTypeStep />
  ) : props.step === StepEnum.Savings ? (
    <SavingsStep isLoading={props.isLoading} />
  ) : props.step === StepEnum.Results ? (
    <ResultsStep
      response={props.result}
      setting={props.setting}
      toggleSetting={props.toggleSetting}
      isLoading={props.isLoading}
    />
  ) : props.step === StepEnum.Financing ? (
    <FinancingStep
      loans={props.loans || []}
      total={total}
      saving={saving}
      setting={props.setting}
    />
  ) : props.step === StepEnum.Contact ? (
    <ContactStep
      loans={props.loans || []}
      total={total}
      saving={saving}
      setting={props.setting}
    />
  ) : props.step === StepEnum.ThankYou ? (
    <ThankYouStep />
  ) : null;
}

export default ConfiguratorStep;

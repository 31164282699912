import Tooltip from 'components/Tooltip';
import HeatTransferBadge from 'pages/configurator/steps/result/components/HeatTransferBadge';
import { ResponseData } from 'shared/types';

interface RatingChangeProps {
  response?: ResponseData;
}

function RatingChangeMobile(props: RatingChangeProps) {
  const tooltip = (
    <Tooltip
      html="Součinitel prostupu tepla říká, jak dobře je váš dům zateplený
                a jaké máte tepelné ztráty. Rozsah škály je A - F.
                Oproti Průkazu energetické náročnosti budovy (PENB) nebere v úvahu zdroj tepla."
      className="tooltip-left ml-2"
    />
  );

  return props.response?.validSetting ? (
    <div className="grid grid-cols-5">
      <div className="col-span-5 bg-primary px-4 py-5 flex items-center">
        <h3 className="text-white flex-1">Součinitel prostupu tepla budovy</h3>
        {tooltip}
      </div>
      <div className="col-span-2 flex items-center bg-lightblue px-2.5 py-2 border-l border-b border-table-border-color">
        <h6>Před rekonstrukcí</h6>
      </div>
      <div className="col-span-3 px-2.5 py-2 border-b border-r border-table-border-color">
        <HeatTransferBadge
          rating={props.response.heatTransferCoefficient.before}
        />
      </div>
      <div className="col-span-2 flex items-center bg-lightblue px-2.5 py-2 border-l border-b border-table-border-color">
        <h6>Po rekonstrukci</h6>
      </div>
      <div className="col-span-3 px-2.5 py-2 border-b border-r border-table-border-color">
        <HeatTransferBadge
          rating={props.response.heatTransferCoefficient.after}
        />
      </div>
    </div>
  ) : null;
}

export default RatingChangeMobile;
